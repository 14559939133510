import React from "react"
import { graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Article,
  ArticleFlexbox,
  ArticleSidebar,
  BlogCard,
  Button,
  CardImageWrapper,
  Container,
  FeatureImage,
  FeatureImageHero,
  Flexbox,
  H1,
  H3,
  //H5,
  HR,
  Paragraph,
  SmallHeader,
  Span,
  TitleSection,
  BlogVideo
} from "../components/styled/components"
//import { Facebook } from "@styled-icons/fa-brands/Facebook"
import Theme from "../components/styled/theme"
import { Helmet } from "react-helmet"
import moment from "moment"
import { useMediaQuery } from "react-responsive"

const BlogPost = ({ data }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <Layout>
      <SEO
        title={data.contentfulBlog.title}
        description={data.contentfulBlog.metaDescription}
        keywords={data.contentfulBlog.metaKeywords}
      />
      <Helmet>
        <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "headline": "${data.contentfulBlog.title}",
          "image": "${data.contentfulBlog.featureImage.fluid.src}",
          "author": {
            "@type": "Person",
            "name": "${data.contentfulBlog.author.name}"
          },
          "publisher": {
            "@type": "Organization",
            "name": "WellHealth"
          },
          "datePublished": "${moment(data.contentfulBlog.publicData).format("YYYY-MM-DD")}",
          "dateModified": "${moment(data.contentfulBlog.lastUpdated).format("YYYY-MM-DD")}"
        }`}
        </script>
      </Helmet>
      {data.contentfulBlog.noIndex &&
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>}
      <FeatureImageHero css={{ maxHeight: "600px" }}>
        <Img
          className="banner__image"
          fluid={data.circle.childImageSharp.fluid}
        />
        <section>
          <Span mb={0}>
            {!!data.contentfulBlog.tags &&
              data.contentfulBlog.tags.map((tag, index) =>
                index !== data.contentfulBlog.tags.length - 1
                  ? `${tag} / `
                  : tag
              )}
          </Span>
          <H1 fontSize="40px !important" fontAlign="left">
            {data.contentfulBlog.title}
          </H1>
          {!data.contentfulBlog.youtubeVideo &&
          <FeatureImage>
            <Img fluid={data.contentfulBlog.featureImage.fluid} />
          </FeatureImage>}
        </section>
        {data.contentfulBlog.youtubeVideo &&
        <BlogVideo>
          <iframe
            width={isTabletOrMobile ? "373" : "560"}
            height={isTabletOrMobile ? "210" : "315"}
            src={data.contentfulBlog.youtubeVideo}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </BlogVideo>}
      </FeatureImageHero>

      <ArticleFlexbox>
        <Article pt={3} pb={5}>
          <Container>
            <Flexbox
              css={{
                justifyContent: "flex-start",
                maxWidth: "340px",
              }}
            >
              <Img
                alt={`portrait photograph of ${data.contentfulBlog.author.name}`}
                fixed={data.contentfulBlog.author.photo.fixed}
                style={{
                  borderRadius: "50%",
                }}
              />
              <Span
                pl={2}
                css={{
                  textTransform: "uppercase",
                }}
              >
                By{" "}
                <Span css={{ fontWeight: "bold", textDecoration: "underline" }}>
                  {data.contentfulBlog.author.name}
                </Span>
              </Span>
            </Flexbox>
            <HR mt={5} mb={5} />
            <Paragraph
              fontSize="1.1rem"
              css={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {moment(data.contentfulBlog.publicData).format("MMMM Do, YYYY")}
            </Paragraph>
            <Paragraph fontSize="1.1rem">
              Last updated: {moment(data.contentfulBlog.lastUpdated).format("MMMM Do, YYYY")}
            </Paragraph>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.contentfulBlog.description.childMarkdownRemark.html,
              }}
              css={{
                fontFamily: "'Baskervville', serif",
                fontSize: "22px",
                lineHeight: "115% !important",
                h1: {
                  marginTop: "70px",
                  lineHeight: "115% !important",
                },
                h2: {
                  marginTop: "50px",
                  lineHeight: "115% !important",
                  fontSize: "32px",
                },
                h3: {
                  marginTop: "30px",
                  lineHeight: "115% !important",
                  fontSize: "26px",
                },
              }}
            ></div>
            <HR mt={5} />
            <Flexbox
              css={{
                justifyContent: "flex-start",
                maxWidth: "340px",
                marginTop: "50px",
              }}
            >
              <Img
                alt={`portrait photograph of ${data.contentfulBlog.author.name}`}
                fixed={data.contentfulBlog.author.photo.fixed}
                style={{
                  borderRadius: "50%",
                }}
              />
              <Span
                pl={2}
                css={{
                  textTransform: "uppercase",
                }}
              >
                By{" "}
                <Span css={{ fontWeight: "bold", textDecoration: "underline" }}>
                  {data.contentfulBlog.author.name}
                </Span>
              </Span>
            </Flexbox>
            {/*<H5 mt={5} mb={1} css={{ fontFamily: Theme.palette.font.body }}>
              Share
            </H5>
            <a>
              <Facebook size={24} />
              </a>*/}
          </Container>
        </Article>
        <ArticleSidebar>
          <SmallHeader textAlign="center">About Us</SmallHeader>
          <BlogCard
            css={{
              maxWidth: "300px",
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              margin: "0 auto",
            }}
          >
            <CardImageWrapper height="200px">
              <Img fluid={data.file.childImageSharp.fluid} />
            </CardImageWrapper>
            <TitleSection height="250px">
              <Span
                css={{
                  textTransform: "none !important",
                  fontFamily: Theme.palette.font.header,
                  fontSize: "16px",
                }}
              >
                What is WellHealth?
              </Span>
              <Paragraph>
                We're a high-tech modern medical practice combining nutrition,
                lifestyle, and cutting-edge medical testing.
              </Paragraph>
              <Button css={{ width: "200px" }} onClick={() => navigate("/experience/")}>Learn more</Button>
            </TitleSection>
          </BlogCard>
          <SmallHeader mt={5} textAlign="left">
            Related Articles
          </SmallHeader>
          {data.allContentfulBlog.edges.map(blog => (
            <Link to={`/blog/${blog.node.slug}/`}>
              <SmallHeader textAlign="left" mb={0} mt={3} fontSize="14px">
                {blog.node.tags.map((tag, index) =>
                  index !== blog.node.tags.length - 1 ? `${tag} / ` : tag
                )}
              </SmallHeader>
              <H3 mt={2} mb={2}>
                {blog.node.title}
              </H3>
              <Flexbox
                css={{
                  justifyContent: "flex-start",
                  maxWidth: "340px",
                }}
                mt={2}
                mb={2}
              >
                <Img
                  alt={`portrait photograph of ${blog.node.author.name}`}
                  fixed={blog.node.author.photo.fixed}
                  style={{
                    borderRadius: "50%",
                  }}
                />
                <Span
                  pl={2}
                  css={{
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  By{" "}
                  <Span
                    css={{ fontWeight: "bold", textDecoration: "underline" }}
                  >
                    {data.contentfulBlog.author.name}
                  </Span>
                </Span>
              </Flexbox>
              <HR />
            </Link>
          ))}
        </ArticleSidebar>
      </ArticleFlexbox>
    </Layout>
  )
}
export default BlogPost

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tags: [String!]) {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "WH_Logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contentfulBlog(slug: { eq: $slug }) {
      author {
        about {
          about
        }
        name
        photo {
          fixed(width: 75, height: 75) {
            ...GatsbyContentfulFixed
          }
        }
      }
      title
      tags
      publicData
      featureImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      youtubeVideo
      description {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 250)
        }
      }
      metaDescription
      metaKeywords
      lastUpdated
      noIndex
    }
    allContentfulBlog(filter: { tags: { in: $tags } }, limit: 5) {
      edges {
        node {
          title
          slug
          featureImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          tags
          author {
            name
            photo {
              fixed(width: 30, height: 30) {
                ...GatsbyContentfulFixed
              }
            }
          }
        }
      }
    }
    circle: file(relativePath: { eq: "WH_Circle_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
